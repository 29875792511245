<template>
  <span v-if="value.includes(name)">
    <v-icon v-if="value[0] === '-'" small>mdi-arrow-down</v-icon>
    <v-icon v-else small>mdi-arrow-up</v-icon>
  </span>
</template>

<script>
export default {
  name: "SortIcon",
  props: ['value','name']
}
</script>

<style scoped>

</style>
