<template>
  <div class="pa-5">
    <div class="d-flex mb-5">
      <h3>Invoices</h3>
      <v-spacer />
      <v-btn outlined class="mr-3" color="orange" @click="syncWithWave">
        Synchronize with Wave
        <span class="mx-3 green--text font-weight-black" v-if="syncInProgress">
          <v-icon color="mr-2">mdi-cog</v-icon>
          in progress
        </span>
      </v-btn>
      <v-btn color="blue" dark rounded :to="{ name: 'invoices-new' }"
        >Create an invoice</v-btn
      >
    </div>
    <div>
      <v-row>
        <v-col :md="6">
          <v-autocomplete
            prepend-inner-icon="mdi-account"
            solo
            label="Filter by customer"
            clearable
            v-model="filters.customer"
            :search-input.sync="customerFilter.q"
            :items="customerFilter.customers"
            :loading="customerFilter.loading"
            item-value="id"
            item-text="name"
            @change="getInvoices"
            flat
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col :md="2">
          <v-select
            :items="waveInvoiceStatuses()"
            @change="getInvoices()"
            v-model="filters.others.status"
            label="Status"
            dense
            hide-details
            outlined
            class="white"
            clearable
          />
        </v-col>
        <v-col :md="2">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="
                  filters.others.dates.length > 0
                    ? filters.others.dates.join(' ~ ')
                    : ''
                "
                prepend-inner-icon="mdi-calendar"
                outlined
                hide-details
                dense
                class="white mr-3"
                placeholder="Select date"
                clearable
                @click:clear="
                  filters.others.dates = [];
                  getInvoices();
                "
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filters.others.dates"
              range
              no-title
              scrollable
              @input="
                filters.others.dates.sort((a, b) => (a > b ? 1 : -1));
                getInvoices();
              "
            />
          </v-menu>
        </v-col>
        <v-col :md="2">
          <v-text-field
            v-model="filters.q"
            flat
            outlined
            dense
            class="white"
            hide-details
            label="Search"
            prepend-inner-icon="mdi-magnify"
            clearable
            @change="getInvoices"
            @click:clear="
              filters.q = '';
              getInvoices();
            "
          />
        </v-col>
      </v-row>
      <div class="d-flex align-center">
        <v-checkbox
          label="Only imported from Wave"
          v-model="filters.others.onlyImported"
          @change="getInvoices()"
          class="mr-3"
          hide-details
          dense
        />
        <v-checkbox
          label="Only created here"
          v-model="filters.others.notImported"
          @change="getInvoices()"
          class="mr-3"
          hide-details
          dense
        />
      </div>
      <v-card class="my-5">
        <v-tabs v-model="filters.others.tabFilter">
          <v-tab
            >Unpaid <v-badge inline :content="unpaidCnt + ''" color="blue-grey"
          /></v-tab>
          <v-tab
            >Draft <v-badge inline :content="draftCnt + ''" color="blue-grey"
          /></v-tab>
          <v-tab>All invoices</v-tab>
        </v-tabs>
        <v-simple-table>
          <thead>
            <tr>
              <th @click="srt('status')">
                Status <SortIcon v-model="filters.sort" name="status" />
              </th>
              <th></th>
              <th @click="srt('createdAt')">
                Created at <SortIcon v-model="filters.sort" name="createdAt" />
              </th>
              <th @click="srt('invoiceDate')">
                Date <SortIcon v-model="filters.sort" name="invoiceDate" />
              </th>
              <th @click="srt('invoiceNumber')">
                Number <SortIcon v-model="filters.sort" name="invoiceNumber" />
              </th>
              <th @click="srt('customer.name')">
                Customer
                <SortIcon v-model="filters.sort" name="customer.name" />
              </th>
              <th @click="srt('totalValue')">
                Total <SortIcon v-model="filters.sort" name="totalValue" />
              </th>
              <th @click="srt('amountDueValue')">
                Amount due
                <SortIcon v-model="filters.sort" name="amountDueValue" />
              </th>
              <th class="text-center">Actions</th>
              <th class="text-center"></th>
              <th class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(invoice, invoiceK) in invoices" :key="invoiceK">
              <td>
                <v-chip
                  small
                  class="invoice-status"
                  :color="
                    waveInvoiceStatuses().filter(
                      x => x.value === invoice.status
                    )[0].color
                  "
                  dark
                  >{{ invoice.status }}</v-chip
                >
              </td>
              <td>
                <v-icon
                  v-if="invoice.importedFromWave"
                  small
                  color="blue"
                  title="Imported from Wave"
                  >mdi-chevron-down-circle</v-icon
                >
                <v-icon v-else small color="orange" title="Created here"
                  >mdi-circle</v-icon
                >
              </td>
              <td>{{ moment(invoice.createdAt).format("YYYY-MM-DD") }}</td>
              <td>{{ moment(invoice.invoiceDate).format("YYYY-MM-DD") }}</td>
              <td>{{ invoice.invoiceNumber }}</td>
              <td>
                <span v-if="invoice.customer">{{ invoice.customer.name }}</span>
              </td>
              <td>
                {{
                  invoice.totalValue.toLocaleString("en-US", {
                    style: "currency",
                    currency: invoice.totalCurrency.code
                  })
                }}
              </td>
              <td>
                {{
                  invoice.amountDueValue.toLocaleString("en-US", {
                    style: "currency",
                    currency: invoice.amountDueCurrency.code
                  })
                }}
              </td>
              <td class="text-center">
                <v-btn
                  icon
                  title="Download invoice"
                  :href="invoice.pdfUrl"
                  target="_blank"
                  ><v-icon v-text="'mdi-file-pdf-outline'"
                /></v-btn>
                <v-btn
                  icon
                  title="View invoice"
                  target="_blank"
                  :href="invoice.viewUrl"
                  ><v-icon v-text="'mdi-eye-outline'"
                /></v-btn>
                <v-btn
                    icon
                    title="Edit invoice"
                    target="_blank"
                    :href="invoice.editUrl"
                ><v-icon v-text="'mdi-pen'"
                /></v-btn>
                <v-btn
                    icon
                    title="Edit invoice position"
                    @click="$set(invoice,'showEditPositions', true)"
                ><v-icon v-text="'mdi-playlist-edit'"
                /></v-btn>
                <InvoicePositions v-model="invoice.showEditPositions" :invoice-id="invoice.id" />
                <v-btn
                  icon
                  @click="
                    $store.commit('confirm', {
                      text:
                        'Are you sure you want delete ' +
                        invoice.invoiceNumber +
                        ' invoice?',
                      confirmFn: () => removeInvoice(invoice)
                    })
                  "
                  ><v-icon v-text="'mdi-delete'"
                /></v-btn>
              </td>
              <td class="text-center">
                <div v-if="invoice.order">
                  <v-icon v-if="invoice.order.toSend" small title="To send"
                    >mdi-truck-fast-outline</v-icon
                  >
                  <v-icon
                    v-if="invoice.order.comment"
                    small
                    :title="invoice.order.comment"
                    >mdi-comment-outline</v-icon
                  >
                  <v-menu
                    v-if="invoice.order.shipments.length > 0"
                    bottom
                    offset-y
                    offset-x
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="green" dark v-bind="attrs" v-on="on" icon>
                        <v-icon title="Shipments">mdi-truck-outline</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="(shipment, shipmentK) in invoice.order.shipments"
                        :key="shipmentK"
                      >
                        <v-list-item-avatar>
                          {{ shipmentK + 1 }}.
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            >{{ shipment.speditor }}
                            {{ shipment.number }}</v-list-item-title
                          >
                          <v-list-item-subtitle>{{
                            moment(shipment.createdAt).format("lll")
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            :href="upsTrackingLink(shipment.number)"
                            target="_blank"
                            icon
                          >
                            <v-icon>mdi-truck</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </td>
              <td class="text-right">
                <v-chip
                  v-if="invoice.lastSentAt"
                  x-small
                  color="green"
                  dark
                  title="Invoice sent"
                >
                  {{ moment(invoice.lastSentAt).format("ll") }}
                </v-chip>
                <v-btn icon @click="showInv(invoice)"
                  ><v-icon>mdi-magnify</v-icon></v-btn
                >
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
      <div class="d-flex">
        <v-spacer />
        <v-pagination
          v-model="filters.page"
          :length="pages"
          total-visible="10"
          @input="getInvoices({ changePage: true })"
        />
      </div>
    </div>
    <v-dialog
      v-if="showInvoice.invoice"
      v-model="showInvoice.show"
      max-width="800px"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title
            >Invoice {{ showInvoice.invoice.invoiceNumber }}</v-toolbar-title
          >
          <v-spacer />
          <v-btn icon @click="showInvoice.show = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <div class="d-flex justify-space-between">
            <div>
              <strong>Status</strong><br />
              {{ showInvoice.invoice.status }}
            </div>
            <div>
              <strong>Customer</strong><br />
              {{ showInvoice.invoice.customer.name }}
            </div>
            <div>
              <strong>Amount due</strong><br />
              {{ showInvoice.invoice.amountDueValue }}
              {{ showInvoice.invoice.amountDueCurrency.code }}
            </div>
            <div>
              <strong>Due on</strong><br />
              {{ moment(showInvoice.invoice.dueDate).format("ll") }}
            </div>
          </div>
          <v-stepper vertical :value="1" flat>
            <v-stepper-step step="1" complete complete-icon="mdi-file-document"
              >Create</v-stepper-step
            >
            <v-stepper-content step="1">
              <div class="d-flex">
                <div>
                  <strong>Created:</strong> on
                  {{ moment(showInvoice.invoice.createdAt).format("lll") }}
                </div>
                <v-spacer />
                <v-btn
                  v-if="showInvoice.invoice.status === 'DRAFT'"
                  outlined
                  rounded
                  color="blue"
                  @click="approveInvoice(showInvoice.invoice)"
                  :loading="showInvoice.approveLoading"
                  >Approve draft</v-btn
                >
              </div>
            </v-stepper-content>
            <v-stepper-step step="1" complete complete-icon="mdi-send-outline"
              >Send</v-stepper-step
            >
            <v-stepper-content step="1">
              <div class="d-flex">
                <div>
                  <strong>Last sent:</strong>
                  {{
                    showInvoice.invoice.lastSentAt
                      ? moment(showInvoice.invoice.lastSentAt).format("lll")
                      : "never"
                  }}
                </div>
                <v-spacer />
                <v-btn outlined rounded color="blue" @click="showSendInvoice()"
                  >Send invoice</v-btn
                >
              </div>
            </v-stepper-content>
          </v-stepper>
          <div v-if="showInvoice.invoice.order">
            <v-textarea
              label="Shipment comment"
              v-model="showInvoice.invoice.order.comment"
              outlined
            />
            <div>
              <v-btn
                depressed
                class="mr-3"
                @click="
                  updateOrder(showInvoice.invoice.order, {
                    comment: showInvoice.invoice.order.comment
                  })
                "
                >Save comment</v-btn
              >
              <v-btn
                v-if="!showInvoice.invoice.order.toSend"
                color="green"
                dark
                depressed
                @click="
                  updateOrder(showInvoice.invoice.order, {
                    comment: showInvoice.invoice.order.comment,
                    toSend: true
                  })
                "
                >Send to realization</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="showInvoice.invoice"
      v-model="showInvoice.sendInvoice.show"
      max-width="800px"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title
            >Send invoice
            {{ showInvoice.invoice.invoiceNumber }}</v-toolbar-title
          >
          <v-spacer />
          <v-btn icon @click="showInvoice.sendInvoice.show = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-text-field label="To" v-model="showInvoice.sendInvoice.to" />
          <v-text-field
            label="Subject"
            v-model="showInvoice.sendInvoice.subject"
          />
          <v-textarea
            label="Message"
            v-model="showInvoice.sendInvoice.body"
            filled
          />
          <v-btn
            :loading="showInvoice.sendInvoice.loading"
            @click="sendInvoice()"
            rounded
            color="blue"
            outlined
            >Send invoice</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import functions from "../mixins/functions";
import globals from "../mixins/globals";
import axios from "axios";
import moment from "moment";
import SortIcon from "../components/SortIcon";
import InvoicePositions from "@/components/InvoicePositions";
export default {
  name: "Invoices",
  components: {InvoicePositions, SortIcon },
  mixins: [functions, globals],
  data() {
    return {
      syncInProgress: false,
      invoices: [],
      pages: 1,
      unpaidCnt: 0,
      draftCnt: 0,
      customerFilter: {
        loading: false,
        q: "",
        customers: []
      },
      showInvoice: {
        show: false,
        invoice: null,
        approveLoading: false,
        sendInvoice: {
          show: false,
          to: "",
          subject: "",
          body:
            "Thank you for order.\n\nThe tracking number is: [SPEDITOR] [NUMBER]",
          loading: false
        }
      },
      filters: {
        q: "",
        customer: null,
        page: 1,
        sort: "-createdAt",
        others: {
          onlyImported: false,
          notImported: false,
          dates: [],
          status: null,
          tabFilter: 2
        }
      },
      moment: moment
    };
  },
  watch: {
    "filters.others.tabFilter": {
      handler() {
        this.filters.others.status = null;
        this.getInvoices();
      }
    },
    "customerFilter.q": {
      handler(val) {
        if (!val) return;

        this.customerFilter.loading = true;
        axios
          .post(this.globals.apiUrl + "/customer/list", {
            q: val,
            page: 1
          })
          .then(res => {
            this.customerFilter.customers = res.data.customers;
          })
          .catch(err => console.log(err))
          .finally(() => {
            this.customerFilter.loading = false;
          });
      }
    }
  },
  created() {
    this.checkSync();
    this.getInvoices();
  },
  methods: {
    showSendInvoice() {
      this.showInvoice.sendInvoice.body = "Thank you for order.";
      if (
        this.showInvoice.invoice.order &&
        this.showInvoice.invoice.order.shipments.length > 0
      ) {
        this.showInvoice.sendInvoice.body +=
          "\n\nThe tracking number is: " +
          this.showInvoice.invoice.order.shipments[0].speditor +
          " " +
          this.showInvoice.invoice.order.shipments[0].number;
      }
      this.showInvoice.sendInvoice.show = true;
    },
    checkSync() {
      axios
        .get(this.globals.apiUrl + "/wave/progress")
        .then(res => {
          this.syncInProgress = res.data.sync;
        })
        .catch(() => {})
        .finally(() => {
          setTimeout(this.checkSync, 5000);
        });
    },
    updateOrder(order, data) {
      axios
        .post(this.globals.apiUrl + "/orders/" + order.id + "/update", data)
        .then(() => {
          this.notyf("Update success!");
        })
        .catch(() => {
          this.notyf("Update error", "error");
        });
    },
    sendInvoice() {
      this.showInvoice.sendInvoice.loading = true;
      axios
        .post(this.globals.apiUrl + "/invoices/send", {
          id: this.showInvoice.invoice.id,
          to: this.showInvoice.sendInvoice.to,
          subject: this.showInvoice.sendInvoice.subject,
          body: this.showInvoice.sendInvoice.body
        })
        .then(res => {
          this.showInvoice.invoice.lastSentAt = res.data.lastSendAt;
          this.showInvoice.invoice.status = "SENT";
          this.notyf("Invoice sent!");
          this.showInvoice.sendInvoice.loading = false;
          this.showInvoice.sendInvoice.show = false;
        })
        .catch(() => {
          this.notyf("Send invoice failed", "error");
          this.showInvoice.sendInvoice.loading = false;
        });
    },
    approveInvoice(invoice) {
      this.showInvoice.approveLoading = true;
      axios
        .post(this.globals.apiUrl + "/invoices/approve", {
          id: invoice.id
        })
        .then(() => {
          invoice.status = "SAVED";
          this.notyf("Invoice approved!");
          this.showInvoice.approveLoading = false;
        })
        .catch(() => {
          this.notyf("Approve failed", "error");
          this.showInvoice.approveLoading = false;
        });
    },
    showInv(invoice) {
      this.showInvoice.invoice = invoice;
      this.showInvoice.sendInvoice.to = invoice.customer.email;
      this.showInvoice.sendInvoice.subject =
        "Invoice #" + invoice.invoiceNumber;
      this.showInvoice.show = true;
    },
    syncWithWave() {
      this.$store.commit("confirm", {
        title: "Synchronize with Wave",
        text:
          "Synchronization will be performed in the background. This process may take several minutes.",
        confirmText: "Schedule sync",
        confirmColor: "orange",
        confirmFn: () => {
          axios
            .get(this.globals.apiUrl + "/wave/sync")
            .then(() => {
              this.notyf("Sync scheduled");
            })
            .catch(reason => {
              try {
                this.notyf(reason.response.data.message, "error");
              } catch (e) {
                this.notyf("Sync failed", "error");
              }
            });
        }
      });
    },
    removeInvoice(invoice) {
      axios
        .post(this.globals.apiUrl + "/invoices/remove", invoice)
        .then(() => {
          this.invoices.forEach((value, index) => {
            if (value.id === invoice.id) {
              this.invoices.splice(index, 1);
            }
            this.notyf("invoice deleted");
          });
        })
        .catch(() => {
          this.notyf("delete error", "error");
        });
    },
    srt(val) {
      this.filters.sort = this.filters.sort.includes(val)
        ? this.filters.sort[0] === "-"
          ? val
          : "-" + val
        : val;
      this.getInvoices();
    },
    getInvoices(opt) {
      if (!opt || !opt.changePage) {
        this.filters.page = 1;
      }

      this.$store.commit("showLoading");
      axios
        .post(this.globals.apiUrl + "/invoices/list", {
          q: this.filters.q,
          page: this.filters.page,
          sort: this.filters.sort,
          customer: this.filters.customer,
          others: this.filters.others
        })
        .then(res => {
          this.pages = res.data.pages;
          this.invoices = res.data.invoices;
          this.unpaidCnt = res.data.unpaidCount;
          this.draftCnt = res.data.draftCount;

          if (localStorage.getItem("show-invoice")) {
            this.invoices.forEach(x => {
              if (x.id === localStorage.getItem("show-invoice")) {
                this.showInv(x);
              }
            });
            localStorage.removeItem("show-invoice");
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    }
  }
};
</script>

<style scoped>
.invoice-status {
  width: 100%;
  border-top-right-radius: 1px !important;
  border-bottom-right-radius: 1px !important;
}
</style>
